$primary-color: #f25f5c;
$primary-yellow: #eeb211;
$primary-blue: #247ba0;
$primary-red: #F74040;
$primary-green: #60B136;
$primary-text-color: darken(white, 60%);

body{
	font-family: 'Montserrat', sans-serif;
	color: $primary-text-color;
	margin: 0;
	padding: 0;
	#pagemenu {
		text-align: center;
		font-size: 14px;
	   	background-color: #50514F;
	   	border-bottom: 2px solid $primary-color;
	   	border-radius: 0 !important;
		.navbar-brand {		
			padding: 12px !important;
    	}
    	.navbar-toggle {
				border-color: $primary-color !important;
				&:hover, &:focus {
					background-color: $primary-color;
					.icon-bar {
						background-color: $primary-color;
					}
				}
				.icon-bar {
					background-color: $primary-color;
				}
				&:hover, &:focus {
					background-color: transparent;
				}
    		}
    	.navbar-nav {
    		float: none !important;
    		display: inline-block;
    		@media (max-width: 576px) {
				display: block;
			}
    		> li{
    			> a{
    				color: white;
	    			background-color: rgba(248, 248, 248, 0);
	    			text-transform: uppercase;
	    			font-size: 12px;
	    			font-weight: 600;
	    			transition: all .2s ease-in;
	    			&:hover, &:focus {
	    				color: white;
	    				background-color: $primary-color;
	    			}
    			}
    		}
    		.active {
    			> a, > a:hover, > a:focus {
					color: rgba(85, 85, 85, 1);
					background-color: rgba(231, 231, 231, 1);
    			}
    		}
   		}
	}
	#paintball {
		background-color: #c7c57c;
		padding: 50px 0;
		background-image: url(../img/camo-line.jpg), url(../img/camo-line.jpg);
		background-position: top, bottom;
		background-repeat: repeat-x, repeat-x;
		;
		//background-size: cover;
		//background-attachment: fixed;
		.container-fluid {
			//background-image: url("../js/vegas/overlays/07.png");
			h2 {
				text-align: center;
				padding-bottom: 50px;
				img {
					display: inline-block;
				}
			}
			p {
				//text-align: left !important;
				font-size: 20px;
				color: #5f5e30 !important;
				@media (max-width: 576px) {
					text-align: center !important;
					font-size: 18px;
				}
			}
			.col-lg-6 {
				text-align: right;
				@media (max-width: 576px) {
					text-align: center !important;
				}
				img {
					width: 95%;
					margin-right: 0 !important;
				}
				&:first-of-type {
					padding-bottom: 50px;
				}
			}
		}
		.cenovniklink {
			padding-top: 50px;
			a {
				background-color: #5f5e30 !important;
			}
		}
	}
	.orderhowto {
		text-align: center;
		display: block;
		margin-top: 70px;
		a {
			text-decoration: none;
			background-color: $primary-color;
			color: white;
			padding: 15px 40px;
			border-radius: 20px;
			font-size: 20px;
			transition: all .25s;
			@media (max-width: 576px) {
				font-size: 14px;
				padding: 10px 20px;
			}
			&:hover {
				background-color: lighten($primary-color, 7%);
			}
		}
		.tutorial {
			text-align: center;
			img {
				border-radius: 10px;
				box-shadow: 0 10px 25px 0 rgba(black, .3);
			}
		}
	}
	.slider-div {
		min-height: 100%;
		position: relative;
		.useroptions {
			position: absolute;
			top: 60px;
			right: 20px;
			font-size: 18px;
			a {
				i {
					padding-right: 5px;
				}
				color: white;
				text-shadow: 1px 2px 3px black;
				&:first-of-type {
					&:after {
						content: " | ";
						&:hover {
							text-decoration: none;
						}
					}
				}
			}
			@media (max-width: 576px) {
				//position: static;
				//margin: 0 auto;
				//padding-top: 60px;
				//text-align: center;
				span {
					display: none;
				}
			}
		}
		.languages {
			position: absolute;
			top: 60px;
			left: 20px;
			font-size: 18px;
			a {
				img {
					padding-right: 5px;
				}
				color: white;
				text-shadow: 1px 2px 3px black;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.header-content {
			position: absolute;
			width: 100%;
			@media (min-width: 576px) {
				top: 100px;
			}
			@media (max-width: 576px) {
				top: 40%;
			}
			&-inner {
				margin-right: auto;
				margin-left: auto;
				max-width: 1000px;
				text-align: center;
				padding: 0 20px;
				img {
					margin: 0 auto;
				}
				h1 {
					display: none;
					/*font-family: Arial, Helvetica, sans-serif;
					color: white;
					margin-bottom: 0;
					text-shadow: 1px 2px 3px black;
					padding-bottom: 5px;
					b {
						font-family: "Arial Black", Gadget, sans-serif;
						text-transform: uppercase;
						font-size: 30px;
						font-weight: 300;
					}
				}
				small {
					border-top: solid 1px white;
					padding-top: 3px;
					color: white;
					text-shadow: 1px 2px 3px black;
					box-shadow: inset -1px 5px 10px -6px black;*/
				}
			}
		}
		.socials {
			@media (min-width: 576px) {
				position: absolute;
				right: -10px;
				top: 40%;
				font-size: 30px;
				display: inline-block;
				z-index: 999;
				&-block {
					background-color: #777;
					display: block;
					padding: 10px 17px 10px 10px;
					margin-bottom: 5px;
					cursor: pointer;
					border-radius: 10px 0 0 10px;
					background-color: $primary-color;
						a {
							color: white;
							text-decoration: none;
						}
					&--telephone {
						font-size: 25px;
					}
					&:hover {
	        			animation: swing 1s ease;
	        			animation-iteration-count: 1;
					}
				}
			}
			@media(max-width: 576px) {
				display: none;
			}
		}
		.telephone {
			@media(max-width: 576px) {
				display: block;
				width: 50px;
				height: 50px;
				bottom: 15px;
				right: 15px;
				z-index: 999;
				position: fixed;
				border-radius: 50%;
				background-color: $primary-color;
				box-shadow: 0 10px 25px 0 rgba(black, .3);
				padding: 12px;
				a {			
					color: white;
					font-size: 25px;								
				}
			}
			@media(min-width: 576px) {
				display: none;
			}
		}
		.angle-down {
			position: absolute;
			display: block;
			color: rgba(255,255,255,0.2);
			font-size: 72px;
			bottom: 0;
			width: 100%;
			text-align: center;

		}
	}
	#about-us {
		padding-bottom: 25px;
		.about-content {
			p {
				text-align: justify;
				text-align-last: center;
			}
		}
		img {
			box-shadow: 0 10px 25px 0 rgba(black, .3);
			border-radius: 10px;
		}
	}
	#reservation {
		min-height: 50%;
		.party-content {
			text-align: center;
			@media (min-width: 576px) {
				p {
					margin: 20px 150px;
				}
			}
		}
		.partycard {
			&-inner {
				position: relative;
				text-align: center;
				padding: 20px;
				height: 500px;
				background-image: url(../img/card1.jpg);
				width: 250px;
				margin: 25px auto;
				box-shadow: 0 10px 25px 0 rgba(black, .3);
				border-radius: 10px;
				color: white;
				h3 {
					margin-top: 10px;
					border-bottom: 2px solid $primary-yellow;
					padding-bottom: 10px;
					text-shadow: 1px 2px 3px black;
				}
				p {
					text-align: justify;
					text-align-last: center;
					text-shadow: 1px 2px 3px black;
				}
				.informacije {
					width: 100%;
					position: absolute;
					bottom: 70;
					left: 0;
					a {
						background-color: $primary-yellow;
						border-radius: 25px;
						padding: 15px 30px;
						color: white;
						text-decoration: none;
						cursor: pointer; 
						box-shadow: 0 10px 25px 0 rgba(black, .3);
						transition: all 0.25s ease-in;
						&:hover {
							background-color: lighten($primary-yellow, 7%);
						}
					}
				}
				&__2 {
					background-image: url(../img/card2.jpg);
					h3 {
						border-color: $primary-color;
					}
					.informacije {
						a {
							background-color: $primary-color;
							&:hover {
								background-color: lighten($primary-color, 7%);
							}
						}
					}
				}
				&__3 {
					background-image: url(../img/card5.jpg);
					h3 {
						border-color: #3a94e0;
					}
					.informacije {
						a {
							background-color: #3a94e0;
							&:hover {
								background-color: lighten(#3a94e0, 7%);
							}
						}
					}
				}
				&__4 {
					background-image: url(../img/card4.jpg);
					h3 {
						border-color: $primary-red;
					}
					.informacije {
						a {
							background-color: $primary-red;
							&:hover {
								background-color: lighten($primary-red, 7%);
							}
						}
					}
				}
			}
		}
	}
	.titletext {
		margin-top: 60px;
		text-align: center;
		font-weight: 500;
		font-family: 'Quicksand', sans-serif;
		text-transform: uppercase;
		&--white {
			color: white;
		}
		&--lowmargin {
			margin-top: 30px;
		}
	}
	.sportsrow {
		.sportinfo {
			text-align: center;
			.aboutsports {
				color: white;
				border-radius: 25px;
				margin-top: 65px;
				h3 {
					position: relative;
					background-color: $primary-color;
					margin-bottom: 0;
					padding: 20px;
					border-radius: 15px;
					&:before {
					content: " ";
					display: block;
					background-image: url("../img/odbojka.png");
					width: 171px;
					height: 152px;
					position: absolute;
					top: -45;
					left: -20;
					}
					&:hover:before {
        			animation: swing 1s ease;
        			animation-iteration-count: 1;
					}
				}
				ul {
					margin: 0 12px;
					text-align: center;
					//background-color: #c9c9c8;
					padding: 20px;
					border-radius: 0 0 10px 10px;
					color: $primary-text-color;
					box-shadow: 0 10px 25px 0 rgba(black, .3);
					border-top: none;
					li {
						padding-left: 25%;
						display: block;
						text-align: left;
						p {
							padding-left: 20px;
							display: inline-block;
						}
						&:last-of-type {
							text-align: center;
							padding: 0;
							margin: 15px 0 10px 0;
							a {
								text-decoration: none;
								background-color: $primary-color;
								color: white;
								padding: 10px 30px;
								border-radius: 10px;
								transition: all .25s;
								&:hover {
									background-color: lighten($primary-color, 7%);
								}
							}
						}
					}
				}		
			}
			&:first-of-type {
				.aboutsports {
					h3 {
						background-color: $primary-color;
						&:before {
							content: " ";
							display: block;
							background-image: url("../img/fudbal.png");
							width: 158px; 
							height: 138px;
							position: absolute;
							top: -40; 
							left: -10;
						}
					}
				}
			}
			&:last-of-type {
				.aboutsports {
					h3 {
						background-color: $primary-color;
						&:before {
							content: " ";
							display: block;
							background-image: url("../img/tenis1.png");
							width: 102px;
							height: 156px;
							position: absolute;
							top: -40;
							left: 0;
						}
					}
				}
			}
		}
	}
	hr {
		border-color: $primary-color;
	}
	@-webkit-keyframes swing
	{
	    15%
	    {
	        -webkit-transform: translateX(5px);
	        transform: translateX(5px);
	    }
	    30%
	    {
	        -webkit-transform: translateX(-5px);
	       transform: translateX(-5px);
	    } 
	    50%
	    {
	        -webkit-transform: translateX(3px);
	        transform: translateX(3px);
	    }
	    65%
	    {
	        -webkit-transform: translateX(-3px);
	        transform: translateX(-3px);
	    }
	    80%
	    {
	        -webkit-transform: translateX(2px);
	        transform: translateX(2px);
	    }
	    100%
	    {
	        -webkit-transform: translateX(0);
	        transform: translateX(0);
	    }
	}
	@keyframes swing
	{
	    15%
	    {
	        -webkit-transform: translateX(5px);
	        transform: translateX(5px);
	    }
	    30%
	    {
	        -webkit-transform: translateX(-5px);
	        transform: translateX(-5px);
	    }
	    50%
	    {
	        -webkit-transform: translateX(3px);
	        transform: translateX(3px);
	    }
	    65%
	    {
	        -webkit-transform: translateX(-3px);
	        transform: translateX(-3px);
	    }
	    80%
	    {
	        -webkit-transform: translateX(2px);
	        transform: translateX(2px);
	    }
	    100%
	    {
	        -webkit-transform: translateX(0);
	        transform: translateX(0);
	    }
	}

	.bar1, .bar2, .bar3 {
	    transition: 0.4s;
	}

	.change .bar1 {
    	-webkit-transform: rotate(-45deg) translate(-2px, 6px) ;
    	transform: rotate(-45deg) translate(-2px, 6px) ;
	}

	.change .bar2 {
    	opacity: 0;
	}

	.change .bar3 {
	    -webkit-transform: rotate(45deg) translate(-3px, -6px) ;
	    transform: rotate(45deg) translate(-3px, -7px) ;
	}
	/**************Login**********************/
	.logindiv {
		min-height: 100%;
		position: relative;
		display: -webkit-box;
    	display: -moz-box;
    	display: -ms-flexbox;
    	display: -webkit-flex;
    	display: flex;
    	align-items: center;
    	justify-content: center;
		&-inner {
			border-radius: 50px;
			@media (max-width: 576px) {
				width: 100%;
				img {
					margin: 20px auto;
				}
			}
			@media (min-width: 576px) {
				width: 600px;
				img {
					width: 50%;
					margin: 20px auto;
				}
			}
			padding: 50px;
			padding-top: 20px;
			background-color: $primary-color;
			color: white;
			text-align: center;
			border: 2px solid white;
			box-shadow: 0 10px 25px 0 rgba(black, .3);
			.inputtext {
				border-radius: 20px;
				border-color: transparent;
				color: $primary-text-color;
				padding: 10px;
				text-align: center;
				font-weight: 600;
				&::placeholder {
					color: darken(white, 30%);
				}		
				&:focus {
					outline: none;
				}
			}
			.submitbtn {
				color: white;
				font-weight: 600;
				border-radius: 20px;
				padding: 10px 70px;
				text-transform: uppercase;
				background-color: $primary-color;
				transition: all 0.25s;
				border: 2px solid white;
				&:hover {
					background-color: white;
					color: $primary-color;
				}
				&:focus {
					outline: 0;
				}
			}
			.error {
				font-size: 12px;
			}
			.forgotpw {
				font-size: 12px;
				font-style: italic;
				color: white;
				text-decoration: underline;
			}
			.reginfo {
				font-size: 10px;
			}

			.mobil {
				margin-bottom: 0;
			}
			.g-recaptcha {
				@media (max-width: 576px) {
						transform-origin: left center;
						-ms-transform: scale(0.75, 0.75); /* IE 9 */
    					-webkit-transform: scale(0.75, 0.75); /* Safari */
    					transform: scale(0.75, 0.75);
    					margin: 0;
					}
					@media (min-width: 576px) {
						-ms-transform: scale(0.85, 0.85); /* IE 9 */
	    				-webkit-transform: scale(0.85, 0.85); /* Safari */
	    				transform: scale(0.85, 0.85);
						margin: 0 auto;
					}
				div {
					margin: 0 auto;
					margin-bottom: 5px;
				}
			}
		}
	}
}

.leftright {
	img {
		border-radius: 10px;
		margin-right: 20px;
		box-shadow: 0 10px 25px 0 rgba(black, .3);
		@media (max-width: 576px) {
			width: 100%;
		}
	}
	p {
		padding-left: 15px;
	}
	.cenovniklink {
		text-align: right;
		padding-top: 40px;
		a {
			background-color: $primary-color;
			border-radius: 10px;
			padding: 15px 30px;
			color: white;
			text-decoration: none;
			cursor: pointer; 
			transition: all 0.25s ease-in;
			&:hover {
				background-color: lighten($primary-color, 7%);
			}
		}
	}
	padding-bottom: 25px;
	@media (max-width: 576px) {
		img {
			box-shadow: none;
			margin: 0 auto;
		}
		p {
			text-align: justify;
			padding: 20px 0 0 0;
		}
		.cenovniklink {
			text-align: center;
			padding-top: 20px;;
		}
	}
}

.loginsite {
	background-image: url("../img/loginbg.jpg");
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
}
.grayback {
	background-color: #666;
	border-radius: 0 0 10px 10px;
}
.whiteback {
	background-color: white;
	border-radius: 0 0 10px 10px;
	padding: 20px;
	text-align: center;
	overflow: auto;
	h2 {
		margin-bottom: 20px;
		padding-bottom: 5px;
		border-bottom: 1px solid $primary-color;
		display: inline-block;
	}
	table {
		margin: 0 auto;
		td,th {
			text-align: center;
		}
	}
}.newscontainer {
	p:first-of-type {
		text-align: center;
		margin: 0;
		padding: 0;
		font-size: 12px;
		margin-bottom: 5px;
		font-style: italic;
		font-weight: 600;
		opacity: .8;
	}
	p:last-of-type {
		text-align: justify;
		min-height: 180px;
		@media (max-width: 576px) {
			border-bottom: 1px solid lighten(#666,10%);
			padding-bottom: 20px;
			margin-bottom: 10px;
		}
	}
	h3 {
		margin: 10px;
		text-align: center;
		font-size: 18px;
		font-weight: 600;
	}
	img {
		width: 100%;
		border-radius: 10px;
	}
}
.makeorder {
	padding: 20px;
	background-image: url("../img/loginbg.jpg");
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	@media (max-width: 576px) {
		padding: 0;
		.container-fluid {
			padding: 15px;
		}
	}
	.modalback, .modalbacksuccess {
		display: none;
    	position: fixed; 
    	z-index: 999;
    	padding-top: 100px;
    	left: 0;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	overflow: auto; 
    	background-color: rgb(0,0,0); 
    	background-color: rgba(0,0,0,0.4);
    	transition: all 0.25s ease-in; 
    	.modalmessage {
    		background-color: #fefefe;
    		border-top: 5px solid $primary-color;
    		margin: auto;
    		padding: 20px;
    		border-radius:10px;
    		width: 400px;
    		@media (max-width: 576px) {
				width: 95%;
			}
    		p:first-of-type {
    			font-size: 25px;
    			text-align: center;
    		}
    		table {
    			margin: 15px auto;
    			td {
					padding: 2px 5px;
				}
    		}
    		div {
    			text-align: right;
    			button {
					border: none;
					padding: 7px 20px;
					margin-top: 10px;
					font-size: 12px;
					border-radius: 20px;
					color: white;
					transition: all 0.25s ease-in;
    				&:first-of-type {
    					background-color: $primary-red;
    					&:hover {
    						background-color: lighten($primary-red, 7%);
    					}
    				}
    				&:last-of-type {
    					background-color: $primary-green;
    					&:hover {
    						background-color: lighten($primary-green, 7%);
    					}
    				}
    				&:focus {
						outline: 0;
					}
    			}
    		}
    		.modalinfo {
    			font-size: 11px;
    			text-align: center;
    		}
    	}
	}
	.successmodal {
		text-align: center;
		background-color: #fefefe;
    	border-top: 5px solid $primary-color;
    	margin: auto;
    	padding: 20px;
    	border-radius:10px;
    	width: 400px;
    	@media (max-width: 576px) {
			width: 95%;
		}
		i {
			color: $primary-green;
		}
		p {
			font-size: 25px;
			text-align: center;
		}
		button {
			border: none;
			padding: 7px 20px;
			margin-top: 10px;
			font-size: 12px;
			border-radius: 20px;
			color: white;
			transition: all 0.25s ease-in;
			background-color: $primary-green;
    		&:hover {
    			background-color: lighten($primary-green, 7%);
    		}
    	}
	}
	.datereserve {
		border-radius: 10px 10px 10px 10px;
		box-shadow: 0 10px 25px 0 rgba(black, .3);
		height: auto;
		@media (max-width: 576px) {
			border: 2px solid white;
		}
		input[type="radio"]	{
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
		}
		input[type="checkbox"]	{
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
		}
		.topbar {
			background-color: $primary-color;
			color: white;
			border-radius: 10px 10px 0 0;
			position: relative;
			img {
				@media (max-width: 576px) {
					margin: 5px 0 15px 0;
				}
				@media (min-width: 576px) {
					margin: 20px;
				}
			}
			div {
				@media (max-width: 576px) {
					text-align: right;
					padding-top: 10px;
				}
				a:first-of-type {
					position: absolute;
					right: 110px;
					top: 20px;
					color: white;
					text-decoration: underline;
					@media (max-width: 576px) {
						position: static;
						margin-right: 10px;
					}
				}
				a:last-of-type {
					position: absolute;
					right: 20px;
					top: 20px;
					color: white;
					text-decoration: underline;
					@media (max-width: 576px) {
						position: static;
					}
				}
			}
		}
		.dayblock {
			padding: 0;
			background-color: #666;
			height: auto;
			border-radius: 0 0 0 10px;
			h2 {
				color: white;
				font-size: 23px;
				text-align: center;
				position: relative;
				@media (max-width: 576px) {
					text-align: left;
					padding-left: 20px;
					&:after {
						content: "\f150";
						font-family: FontAwesome;
						color: lighten(#666666, 20%);
						font-size: 24px;
						position: absolute;
						right: 15px;
						top: -5px;
					}
				}
			}
			.checkboxdiv {
				color: white;
				@media (max-width: 576px) {
					display: none;
				}
				input[type="radio"]:checked+label {
					background-color: $primary-color;
					@media (min-width: 576px) {
						padding-left: 35px;
					} 
				}
				.checklabel {
					display: block;
					padding: 7px 10px 7px 10px;
					border-bottom: 1px solid #6f6f6f;
					border-top: 1px solid #6f6f6f;
					cursor: pointer;
					margin: 0;
					transition: all 0.25s ease-in;
					@media (max-width: 576px) {
						text-align: center;
						padding: 10px;
						border: none;
						margin-bottom: 5px;
					}
					i {
						font-style: normal;
						font-weight: 300; 
					}
				}
			}
		}
		.timeblock {
			padding: 0;
			background-color: white;
			height: auto;
			text-align: center; 
			border-radius: 0 0 10px 0;
			@media (max-width: 576px) {
				border-radius: 0 0 10px 10px;
				form[name="fieldform"] {
					display: none;
				}
			}
			.fieldslist {
				background-color: #666;
				margin: 0;
				padding: 0;
				text-align: center;
				padding-top: 5px;
				form {
					margin: 0;
					label {
						color: white;
						display: inline-block;
						padding: 10px 10px;
						transition: all 0.25s ease-in;
						cursor: pointer;
						border-right: 1px solid #6f6f6f;
						@media (max-width: 576px) {
							display: block;
						}
						&:last-of-type {
							border: none;
						}
					}
					input[type="radio"]:checked+label{
						background-color: $primary-color;
					}
				}
			}
			h2 {
				text-align: center;
				font-size: 30px;
				display: inline-block;
				padding: 5px;
				border-bottom: 1px solid $primary-color;
				margin-top: 10px;
			}
			.whiteh2 {
				color: white;
				font-size: 23px;
				text-align: center;
				border: none;
				display: block;
				text-align: left;
				padding-left: 20px;
				position: relative;
				margin: 8px 0 6px 0;
				&:after {
					content: "\f150";
					font-family: FontAwesome;
					color: lighten(#666666, 20%);
					font-size: 24px;
					position: absolute;
					right: 15px;
					top: -2px;
				}
				@media (min-width: 576px) {
					display: none;
				}
			}
			.terminselect {
				display: block;
				font-weight: 600;
				font-size: 18px;
				color: white;
				background-color: $primary-red;
				margin: 0 10px;
				border-radius: 5px;
				transition: all 0.25s ease-in;
				&--inner {
					padding-left: 3px;
				}
			}
			.timechbox {
				padding: 10px 50px;
				overflow: auto;
				height: 450px;
				input[type="checkbox"]+label{
					border: 2px solid white;
					padding:5px 10px;
					border-radius: 20px;
					transition: all 0.25s ease-in;
				}
				input[type="checkbox"]:checked+label{
					border-color: $primary-color;
				}
				label {
					font-size: 16px;
					cursor: pointer;
				}
				.not-allowed {
					text-decoration: line-through;
					opacity: 0.3;
					cursor: not-allowed;
				}
			}
			.orderinfo {
				&-content {
					color: white;
					padding: 20px;
					border-top: 1px dashed #bfbfbf;
					border-bottom: 1px dashed #bfbfbf;
					text-align: right;
					margin: 10px;
					@media (max-width: 576px) {
						text-align: center;
					}
					.display1 {
						text-align: left;
						#display {
							color: #666;
							display: inline-block;
							font-size: 15px;
							@media (max-width: 576px) {
								display: block;
								margin: 0 auto;
							}
							td {
								padding: 2px 5px;
							}
						}
					}
					button {
						background-color: $primary-green;
						border: none;
						padding: 10px 25px;
						font-size: 18px;
						border-radius: 20px;
						margin-top: 25px;
						opacity: 0.6;
						transition: all 0.25s;
						&:hover {
							background-color: lighten($primary-green, 7%);
						}
						&:focus {
							outline: 0;
						}
					}
				}
			}
		}
	}
}

#footer {
	margin-top: 50px;
	padding: 20px;
	background-color: #50514F;
	box-shadow: 0 -7px 25px 0 rgba(black, .3);
	.footertext {
		text-align: center;
		color: white;
		font-size: 12px;
		border-top: 1px solid white;
		margin-top: 10px;
		padding-top: 5px;
	}
	p {
		margin-top: 15px;
		color: white;
		text-align: justify;
		text-align-last: center;
	}
	img {
		width: 100%;
	}
	ul {
		color: white;
		li {
			b {
				padding-left: 5px;
				font-size: 15px;
			}
			list-style-type: none;
			margin: 20px 0;
			a {
				color: white;
				text-decoration: none;
				transition: all .2s ease-in;
				&:hover {
					text-decoration: underline;
				}
			}
			/*&:nth-of-type(1) {
				&:before {
					font-family: 'FontAwesome';
					content: "\f3cd";
					padding-right: 5px;
				}
			}*/
		}
	}
}